
#tabsScrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
}

#tabsScrollbar::-webkit-scrollbar {
  background-color: #f5f5f5;
  height: 5px;
}

#tabsScrollbar::-webkit-scrollbar-thumb {
  background-color: #9ae9cb;
  border-radius: 10px;
}


#attributePanelScrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
}

#attributePanelScrollbar::-webkit-scrollbar {
  background-color: #f5f5f5;
  width: 5px;
}

#attributePanelScrollbar::-webkit-scrollbar-thumb {
  background-color: #9ae9cb;
  border-radius: 10px;
}
